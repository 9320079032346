import React from "react";
import {
  Container,
  Grid,
  CssBaseline,
  StylesProvider,
} from "@material-ui/core/";
import logo from "./assets/knito.svg";
import "./styles.scss";

const App = (props) => {
  const { children } = props;
  return (
    <StylesProvider injectFirst>
      <Container component="main" maxWidth="md">
        <section className={"box--main"}>
          <CssBaseline />
          <Grid container>
            <Grid item md={6} sm={12} xs={12} className={"box--input"}>
              <img src={logo} className="logo" alt="logo" draggable={false} />
              {children}
              <ul className="link--footer">
                <li>
                  <a href="https://knito.com/privacy/">Privacy policy</a>
                </li>
                <li>
                  <a href="https://knito.com/terms/">Terms</a>
                </li>
                <li>
                  <a href="https://knito.com/knito-about-us/">About</a>
                </li>
              </ul>
            </Grid>
            <Grid item md={6} sm={12} xs={12} className={"box--image"}></Grid>
          </Grid>
        </section>
      </Container>
    </StylesProvider>
  );
};

export default App;
