import React from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core/";
import App from "./App";

const SignIn = () => {
  return (
    <App>
      <form
        id="kc-form-login"
        onSubmit={() => {
          login.disabled = true;
          return true;
        }}
        action={formActionUrl}
        method="post"
        autoComplete="off"
        className={"input--field"}
      >
        <h1>Sign in to your account</h1>
        <FormControl
          margin="normal"
          required
          fullWidth
          className="container--email"
        >
          <InputLabel htmlFor="name" className="input--label">
            Username
          </InputLabel>
          <Input
            id="username"
            name="username"
            type="text"
            className="input--text"
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name" className="input--label">
            Password
          </InputLabel>
          <Input
            name="password"
            id="password"
            type="password"
            className="input--text"
          />
        </FormControl>
        <FormHelperText
          className="error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        {resetPathUrl && (
          <a href={resetPathUrl} className="link--forgot">
            Forgot your password?
          </a>
        )}
        <FormControlLabel
          control={<Checkbox id="rememberMe" name="rememberMe" />}
          label="Remember me"
          className="checkbox--signin"
        />
        <div className="container--signin">
          <Button
            variant="contained"
            className="button--signin"
            type="submit"
            fullWidth
          >
            Sign In
          </Button>
          {registerUrl && (
            <div className="container--account">
              <span className="label--signup">Don't have an account? </span>
              <a href={registerUrl} className="link--signup">
                Sign Up
              </a>
            </div>
          )}
        </div>
        {googleAuthLink && (
          <div className="container--google">
            <a href={googleAuthLink}>
              <img
                src={`${resourcesPath}/img/google.png`}
                alt="google-auth"
                className="google--image"
              />
            </a>
          </div>
        )}
      </form>
    </App>
  );
};

ReactDOM.render(<SignIn />, document.getElementById("root"));
